@import "styles/variables";

.text {
  color: $primary-bg;
}

.container {
  position: relative;
  display: inline-block;
  max-height:150px
}